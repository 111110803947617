.page-not-found {
    max-width: 500px;
    margin: 0 auto;
    /*margin-top: 50px;*/
    padding: 40px;
    border: 2px solid #3c2683; 
    text-align: center;   
    background-color: #ffffff;
}

.page-not-found .title {
    font-size: 50px;
    letter-spacing: 10px;
    margin-bottom: 10px;
    color : red;
}

.page-not-found .desc {
    font-size: 20px;
    margin-bottom: 20px;
}

.go-back-btn {
    min-width: 160px;
    background-color: #3c2683;
    border : 1px solid #3c2683;
}

.go-back-btn:hover {
    background-color: #626BE3 !important;
}