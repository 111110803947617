.logo {
  /* height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;*/
  height: 64px;
  padding-top: 7px;
  background-color: rgb(59 52 144);
}
/* 
  # rgb(59 52 144) => Couleur foncée de PROSUMA
  # rgba(45, 80, 145, 1) => Bleu pur initiale */
.ant-layout-sider-trigger {
  background-color: rgb(59 52 144);
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(59 52 144)
}

.ant-menu-item-selected a, .ant-menu-item-selected>a, .ant-menu-item:hover,
.ant-menu-submenu-title:hover, .ant-menu-item>a :hover, .ant-menu-item-selected>a:hover,
.ant-menu-item-active>a:hover, .ant-menu-submenu-selected,
.ant-menu-item.ant-menu-item-selected {
  color: #FECC00 !important;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, 
.ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #FECC00  /*#1890ff; */
}

.antd-table-odd {
  background-color: #f9f9f9;
}

.antd-table-even {
  background-color: #ffffff;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding: 20px;
}

.steps-action {
  margin-top: 24px;
}